import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import axios from 'axios';
import styled from 'styled-components'
import Layout from '../layouts/default';
import {
  Card, FAQ, FooterNote, HeroSection, Roadmap, Slide,
  Team, TextImage, CountdownTimer, Modal, Seo
} from "../components"

// const URL = "";

const IndexPage = () => {
  const [totalSold, setTotalSold] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { indexPageData: { intro, feature, modalDescription, note, cMonth,
    cDay, cYear, hour, minutes, showCountdownTimer, seo },
    globalInfo: { footerImage, logo, favicon } } = useStaticQuery(graphql`
    query IndexPageQuery {
      indexPageData: datoCmsHomepage {
        intro {
          model {
            apiKey
          }
          title
          description
          image {
            url
          }
          invert
          callToAction
        }
        modalDescription
        note
        showCountdownTimer
        cMonth
        cDay
        cYear
        hour
        minutes
        feature {
          title
          description
          icon {
            url
          }
        }
        seo {
          title
          description
          image {
            url
          }
        }
      }
      globalInfo: datoCmsGlobalInfo {
        footerImage {
          url
        }
        logo {
          url
        }
        favicon {
          url
        }
      }
    }
  `);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.addEventListener('scroll', handleScroll);
  },[]);

  // useEffect(async () => {
  //   const result = await axios(
  //     URL, {
  //       method: 'GET',
  //       mode: 'no-cors',
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  //       }
  //     }
  //   );
  //
  //   setTotalSold(result.data.sold);
  // }, []);

  return (
    <Layout isModalOpen={isModalOpen}>
      <Seo
        title={seo.title}
        description={seo.description}
        image={seo.image?.url}
        favicon={favicon.url}
      />
      <Modal
        logo={logo.url}
        text={modalDescription}
        note={note}
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <HeroSection />
      <StyledCardSection className="card-section wrapper short">
        {feature?.map((feat, i) => (
          <Card
            icon={feat.icon.url}
            title={feat.title}
            info={feat.description}
          />
        ))}
      </StyledCardSection>
      {showCountdownTimer && (
        <CountdownTimer
          month={cMonth}
          day={cDay}
          year={cYear}
          hours={hour}
          minutes={minutes}
        />
      )}
      {intro?.map((item) => (
        <TextImage
          image={item.image.url}
          title={item.title}
          description={item.description}
          invert={item.invert}
          cta={item.callToAction}
          ctaOnClick={() => setIsModalOpen(true)}
        />
      ))}
      <Slide />
      <Roadmap />
      <Team />
      <FAQ />
      {footerImage && (
        <StyledPreFooter
          className={"PreFooter"}
          data-sal="fade"
          data-sal-easing="ease"
          data-sal-duration="100"
        >
          <div className={"gradient"}/>
          <img src={footerImage.url} alt="p" />
        </StyledPreFooter>
      )}
      <FooterNote />
    </Layout>
  )
}

export const StyledCardSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  
  @media (min-width: 768px) {
    top: -3rem;
  }
  
  @media (min-width: 1024px) {
    flex-flow: row nowrap;
    top: -5rem;
  }

  .Card {
    margin-bottom: 2em;
  }
`

export const StyledPreFooter = styled.section`
  position: relative;
  bottom: -15px;
  
  @media (min-width: 768px) {  
    bottom: -30px;
  }
  
  @media (min-width: 1024px) {  
    bottom: -50px;
  }
  
  
  .gradient {
    display: block;
    width: 100%;
    height: 200px;
    position: absolute;
    top: -50px;
    left: 0;
    z-index: 5;
    
    background-image: linear-gradient(
            hsla(217.02, 56.63%, 16.27%, 0),
            hsla(217.02, 55.29%, 16.67%, 0),
            hsla(216.25, 55.81%, 16.86%, 0),
            hsla(215, 54.55%, 17.25%, 0),
            hsla(215, 53.33%, 17.65%, 0),
            hsla(214.29, 53.85%, 17.84%, 0),
            hsla(213.75, 52.17%, 18.04%, 0),
            hsla(213.75, 51.06%, 18.43%, 0),
            hsla(211.84, 51.58%, 18.63%, 0),
            hsla(211.84, 50.52%, 19.02%, 0),
            hsla(211.84, 49.49%, 19.41%, 80%),
            hsla(211.84, 48.51%, 19.8%, 80%),
            hsla(210.61, 48.51%, 19.8%, 80%),
            hsla(209.39, 47.57%, 20.2%, 80%),
            hsla(209.39, 46.67%, 20.59%, 80%),
            hsla(209.39, 45.79%, 20.98%, 80%),
            hsla(209.39, 44.95%, 21.37%, 80%),
            hsla(206.94, 44.95%, 21.37%, 80%),
            hsla(206.94, 44.14%, 21.76%, 80%),
            hsla(206.94, 43.36%, 22.16%, 80%),
            hsla(206.25, 42.11%, 22.35%, 80%),
            hsla(206.25, 41.38%, 22.75%, 80%),
            hsla(205, 40.68%, 23.14%, 80%),
            hsla(205, 40%, 23.53%, 80%),
            hsla(203.75, 40%, 23.53%, 80%),
            hsla(203.75, 39.34%, 23.92%, 80%),
            hsla(203.75, 38.71%, 24.31%, 80%),
            hsla(201.7, 37.6%, 24.51%, 80%),
            hsla(201.7, 37.01%, 24.9%, 80%),
            hsla(200.87, 35.94%, 25.1%, 80%),
            hsla(200.87, 35.38%, 25.49%, 80%),
    );
  }
  
  img {
    width: 100%;
  }
`;

export default IndexPage