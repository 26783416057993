import * as React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from "styled-components";
import SubadaiBaan from "../assets/fonts/subadai_baan/subadaiboldital.woff";
import SubadaiBaan2 from "../assets/fonts/subadai_baan/subadaiboldital.woff2";
import SubadaiBaanTff from "../assets/fonts/subadai_baan/subadaiboldital.ttf";
import { theme } from "../theme";

const Layout = ({ children, isModalOpen }) => {
  return (
    <StyledLayout isModalOpen={isModalOpen}>
      <div>
        <GlobalStyles isModalOpen={isModalOpen} />
        <main>{children}</main>
      </div>
    </StyledLayout>
  )
}

// note: import ThemeProvider if theming is desired
export const GlobalStyles = createGlobalStyle`
  /* fonts */
  @font-face {
    font-family: "Subadai Baan Bold Italic";
    src: url(${SubadaiBaan2}) format("woff2"),
        url(${SubadaiBaan}) format("woff"),
        url(${SubadaiBaanTff}) format("ttf");
    font-weight: normal;
    font-style: normal;
  }

  /* normalized */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 16px;
    line-height: 1.4;
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
    font-family: ${theme.fonts.secondary};
    
    @media (min-width: 320px) {
      font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }
    
    @media (min-width: 1000px) {
      font-size: 22px;
    }
  }
  
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${theme.colors.primary};
    font-family: ${theme.fonts.secondary};
    font-weight: normal;
    color: ${theme.colors.white};
    overflow: ${({ isModalOpen }) => isModalOpen && 'hidden'}
  }

  h1 {
    width: 50%;
    padding-bottom: .2rem;
    font-family: ${theme.fonts.primary};
    font-weight: normal;
    font-size: 2.5em;
    line-height: normal;
    color: ${theme.colors.secondary};

    @media (min-width: 768px) {
      font-size: 4em;
    }

    &.inverted {
      color: ${theme.colors.primary};
    }

    &.underlined {
      border-bottom: 1px solid ${theme.colors.secondary};

      &.inverted {
        color: ${theme.colors.primary};
        border-bottom: 1px solid ${theme.colors.primary};
      }
    }
  }

  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 30px;
    
    @media (min-width: 1024px) {
      font-size: 1.8rem;
    }
  }
  
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 1.4em;
    font-weight: normal;

    @media (min-width: 768px) {
      font-size: 1.3em;
    }

  }

  h4 {
    font-family: ${theme.fonts.primary};
    font-size: 1.125em;
    font-weight: 600;

    @media (min-width: 768px) {
      font-size: 1.2em;
    }
  }

  p, li {
    font-size: 16px;
    font-weight: 300;
    
    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* common classes */
  .wrapper {
    width: 90%;
    margin: 3rem auto;
    
    @media (min-width: 1024px) {
      width: 85%;
      margin: 8rem auto;
    }
    
    @media (min-width: 2560px) {
      width: 1200px;
    }

    &.short {
      margin: 0 auto;
    }

    &.medium {
      margin: 3rem auto;
    }

    &.wide {
      width: 100%;
    }
  }
  .section-title {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
  }
  

  .gradient-wrapper {
    /* background-color: blue; */
    position: relative;
    z-index: 2;
    margin-top: -1rem;
    padding-top: 5rem;

    background-image: linear-gradient(
      hsla(120, 10%, 100%, 0.3),
      hsla(120, 10%, 100%, 0.6),
      hsla(0, 10%, 100%, 0.1),
      hsla(0, 20%, 100%, 0)
    );
  }

  .w-70 {
    width: 100%;
    margin: auto;
    
    @media (min-width: 1024px) {
      width: 70%;
    }
  }

  .sp-sm {
    padding: .50rem;
  }

  .sp-md {
    padding: 1rem;
  }
  
  .mb-sm {
    margin-bottom: 1rem;
  }
`;

export const StyledLayout = styled.div`
  overflow: ${({ isModalOpen }) => isModalOpen && 'hidden'}
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
