import React from 'react';
import { StyledCard } from './style';

const Card = ({ icon, title, info, className, style }) => (
  <StyledCard className={`Card ${className}`} style={style}>
    <div className="icon">
      <img src={icon} alt="icon" className="icon" />
    </div>
    <h2 className="title">{title}</h2>
    <p className="infos">{info}</p>
  </StyledCard>
);

Card.defaultProps = {
  className: ''
};

export default Card;