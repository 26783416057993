import styled from 'styled-components';

export const StyledSlide = styled.section`
  .swiper-wrapper {
    transition-timing-function: linear;
  }

  .title {
    text-align: right;
    margin-left: auto;
  }

  .slides {
    margin-top: 2rem;
  }

  .slide {
    width: 100%;
    margin-left: 1rem;
    
    @media (min-width: 1200px) {
      width: calc((100% / 3) - 1rem);
    }

    img {
      width: 100%;
    }
  }
`