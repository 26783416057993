import styled from 'styled-components';
import { theme } from "../../theme"

export const StyledButton = styled.button`
  min-width: 100px;
  padding: .5rem 1rem;
  background-color: ${theme.colors.secondary};
  border: none;
  font-size: 1.2rem;
  color: ${theme.colors.white};
  clip-path: polygon(10% 0, 100% 0, 100% 80%, 90% 100%, 0 100%, 0 20%);
  cursor: pointer;
`;