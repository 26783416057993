import styled from 'styled-components';

export const StyledLogo = styled.div`
  width: 35px;
  will-change: transform;

  @media (min-width: 768px) {
    width: 60px;
  }

  @media (min-width: 1024px) {
    width: 85px;
  }

  img {
    width: 100%;
  }
`;