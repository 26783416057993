import styled from 'styled-components';
import { theme } from '../../theme';

export const StyledModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  pointer-events: ${({ open }) => open ? 'auto' : 'none'};
  
  //&.animate {
  //  opacity: 1;
  //  transition: opacity 2s ease-in-out;
  //}

  .overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${theme.colors.primary};
    opacity: ${({ open }) => open ? .4 : 0};
  }

  .content {
    width: 95%;
    height: 500px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    background: rgba(40,1,37, .8);

    clip-path: polygon(10% 0, 100% 0, 100% 90%, 90% 100%, 0 100%, 0 10%);
    transition: all .1s ease-in-out;
    opacity: ${({ open }) => open ? 1 : 0};
    transform: ${({ open }) => open ? 'translateY(0)' : 'translateY(10px)'};
    
    @media (max-width: 1024px) {
      .dynamic-text, .note, p {
        width: 98%;
        word-break: break-all;
      }
    }
    
    @media (min-width: 1024px) {
      width: 40%;
    }
    
    & div {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
      
      p {
        font-size: 14px;
        text-align: center;
      }
    }

    .counter {
      font-size: 1.6rem;
      margin-top: 3rem;
    }
  }
  
  .close-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 15px;
    right: 15px;

    @media (min-width: 1200px) {
      display: none;
    }
  }
  
  .note {
    margin-top: auto;
    font-size: 14px;
    text-align: center;
  }
  
  .logo {
    width: 80px;
    margin-bottom: 1rem;
    
    img {
      width: 100%;
    }
  }
`;
