import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { StyledFAQ } from './style';

import { Accordion } from "./Accordion";

const FAQ = ({ title, faqs }) => {
  const { faqData: { faqTitle, faq } } = useStaticQuery(graphql`
    query TextImageQuery {
      faqData: datoCmsHomepage {
        faqTitle
        faq {
          id
          question
          answer
        }
      }
    }
  `)

  return (
    <StyledFAQ
      className="wrapper"
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration="200"
    >
      {faqTitle && <h1 className="section-title">{faqTitle}</h1>}
      {faq && (
        <div className="wrapper medium w-50">
          {faq?.map(({ question, answer }, i) => (
            <div
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-delay={`${(i + 1) * 100}`}
              data-sal-duration="200"
            >
              <Accordion
                key={i}
                title={question}
                content={answer}
              />
            </div>
          ))}
        </div>
      )}
    </StyledFAQ>
  )
}

export default FAQ;