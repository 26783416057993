import React from 'react';
import closeIcon from '../../assets/icons/close_white.png';
import { StyledModal } from './style';

const Modal = ({ logo, text, note, counter, open, setIsModalOpen }) => {
  return (
    <StyledModal
      className={`Modal`}
      open={open}
    >
      <div className="overlay" onClick={() => setIsModalOpen(false)} />
      <div className="content">
        <img
          src={closeIcon}
          alt="close-icon"
          className="close-icon"
          onClick={() => setIsModalOpen(false)}
        />
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        {text && (
          <div className="dynamic-text">
            <div className="text" dangerouslySetInnerHTML={{ __html: text }} />
            {counter && <h2 className="counter">{counter}&nbsp; total sold</h2>}
          </div>
        )}
        {note && <div className="note" dangerouslySetInnerHTML={{ __html: note }} />}
      </div>
    </StyledModal>
  )
}

export default Modal;