import styled from 'styled-components';

export const StyledFooterNote = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  
  @media (min-width: 1024px) {
    height: 90px;
  }
  
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    
    @media (min-width: 1024px) {
    }
    
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  
  p {
    position: relative;
    z-index: 1;
  }
`;