import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import bgSlash from '../../assets/images/bg-slash.svg';
import { StyledTeam } from './style';

const Team = ({ className }) => {
  const { teamData: { team, teamTitle } } = useStaticQuery(graphql`
    query TeamQuery {
      teamData: datoCmsHomepage {
        team {
          name
          role
          image {
            url
          }
        }
        teamTitle
      }
    }
  `);

  return (
    <StyledTeam
      className={`wrapper short Team ${className}`}
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-duration="200"
    >
      {teamTitle && <h1 className="section-title">{teamTitle}</h1>}
      {team && (
        <div className="members">
          {team?.map(member => (
            <div className="member">
              <div className="image">
                <img src={member.image?.url ?? ''} />
              </div>
              <div
                className="info"
                style={{
                  background: `url(${bgSlash})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              >
                <h3>{member.name}</h3>
                <p>{member.role}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </StyledTeam>
  )
}

export default Team;