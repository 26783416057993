import styled from 'styled-components';
import { theme } from '../../theme'

export const StyledTeam = styled.section`
  .title {
    text-align: right;
    margin-left: auto;
  }

  .members {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;

    .member {
      width: calc((100% / 2) - 1rem);
      margin-bottom: 2rem;
      
      @media (min-width: 1200px) {
        width: calc((100% / 3.5) - 3rem);
      }
    }

    .image {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .info {
      margin-top: 0.8rem;
      padding: 0.5rem;
      background-color: ${theme.colors.secondary};
      text-align: center;
    }
  }
`