import styled from 'styled-components';
import { theme } from '../../theme';

export const StyledCard = styled.div`
  width: 90%;
  min-height: 350px;
  position: relative;
  z-index: 3;
  padding: 1rem;
  background-color: ${theme.colors.secondary};
  text-align: center;
  clip-path: polygon(
    13% 0,
    100% 0,
    100% 35%,
    100% 90%,
    89% 100%,
    50% 100%,
    0 100%,
    0 70%,
    0% 35%,
    0 12%
    );
    
  @media (min-width: 768px) {
    width: calc(100% / 1.8);
  }
  
  @media (min-width: 1024px) {
    width: calc(100% / 3.5);
  }
  
  .icon {
    width: 80px;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    
    @media (min-width: 768px) {
      width: 100px;
    }
    
    img {
      width: 100%;
    }
  }

  .title {
    width: 70%;
    margin: auto;
    margin-bottom: 0.5rem;
    
    font-size: 1.6rem;
    
    @media (min-width: 768px) {
      font-size: 1.8rem;
    }
  }
`