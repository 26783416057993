import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import footerBg from '../../assets/images/footer-bg.svg'
import { StyledFooterNote } from "./style"

const FooterNote = () => {
  const { footerNoteData: { footerNote } } = useStaticQuery(graphql`
    query FooterNoteQuery {
      footerNoteData: datoCmsGlobalInfo {
        footerNote
      } 
    }
  `);

  return (
    <StyledFooterNote
      style={{
        background: `url(${footerBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <p>{footerNote}</p>
    </StyledFooterNote>
  )
}

export default FooterNote;