import React, { useEffect, useState } from 'react';
import { StyledCountdownTimer } from './style';

const CountdownTimer = ({ month, day, year, hours, minutes }) => {
  const [state, setState] = useState({
    deadline: `${year}-${month}-${day}-${hours}:${minutes}:00`,
    days: "0",
    hours: "0",
    minutes: "0",
    seconds: "0"
  });

  function getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      console.log("Date passed");
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));

      setState({
        ...state,
        days: days < 10 ? `0${days}` : days,
        hours: hours < 10 ? `0${hours}` : hours,
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      });
    }
  }

  useEffect(() => {
    setInterval(() => getTimeUntil(state.deadline), 1000);

    return () => clearInterval();
  })

  return (
    <StyledCountdownTimer
      className="wrapper short CountdownTimer"
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration="500"
    >
      <h2 className="timer-title">MINT IN:</h2>
      <div className="timer">
        <div className="time-wrapper">
          <h2 className="time">{state.days}</h2>
          <span className="time-label">days</span>
        </div>
        <span className="separator">:</span>
        <div className="time-wrapper">
          <h2 className="time">{state.hours}</h2>
          <span className="time-label">hours</span>
        </div>
        <span className="separator">:</span>
        <div className="time-wrapper">
          <h2 className="time">{state.minutes}</h2>
          <span className="time-label">minutes</span>
        </div>
        <span className="separator">:</span>
        <div className="time-wrapper">
          <h2 className="time">{state.seconds}</h2>
          <span className="time-label">seconds</span>
        </div>
      </div>
    </StyledCountdownTimer>
  );
};

export default CountdownTimer;