import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import rightBg from '../../assets/images/roadmap_bg_right.png';
import leftBg from '../../assets/images/roadmap_bg_left.png';

import { StyledRoadmap } from './style';

const Roadmap = ({ className }) => {
  const { roadmapData: { roadmap, roadmapTitle } } = useStaticQuery(graphql`
    query RoadmapQuery {
      roadmapData: datoCmsHomepage {
        roadmapTitle
        roadmap {
          title
          description
          isdone
        }
      }
    }
  `);

  return (
    <StyledRoadmap
      className={`Roadmap ${className}`}
    >
      <div className="roadmap-bg right">
        <img src={rightBg} alt="A dinosaur"/>
      </div>
      <div className="wrapper wide">
        <div className="wrapper short">
          {roadmapTitle && <h1 className="section-title">{roadmapTitle}</h1>}
          <div className="timeline">
            {roadmap?.map((timeline, i) => (
              <div
                className={`container ${(i % 2) === 0 ? 'right' : 'left'}`}
                data-sal="fade"
                data-sal-easing="ease"
                data-sal-delay={`${(i + 1) * 100}`}
                data-sal-duration="200"
              >
                <div className={`content ${timeline.isdone && 'isActive'}`}>
                  <h2 className="title">{timeline.title}</h2>
                  <p>{timeline.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="roadmap-bg left">
        <img src={leftBg} alt="A dinosaur"/>
      </div>
    </StyledRoadmap>
  )
}

Roadmap.defaultProps = {
  className: '',
};

export default Roadmap;