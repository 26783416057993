import styled from 'styled-components';
import { theme } from '../../theme';

export const StyledCountdownTimer = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem !important;
  
  @media (min-width: 1024px) {
    margin-bottom: 8rem !important;
  }
  
  .timer-title {
    margin-bottom: 2rem;
    font-family: ${theme.fonts.secondary};
    font-size: 16px;
    font-weight: bolder;
    
    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }
  
  .timer {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
  }
  
  .time-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-right: .50rem;

    @media (min-width: 1024px) {
      margin-right: 2rem;
    }
  }
  
  .time {
    margin-bottom: .8rem;
    font-size: 4rem;
    color: ${theme.colors.secondary};

    @media (min-width: 1024px) {
      margin-bottom: 2rem;
      font-size: 5rem;
    }
  }
  
  .time-label {
    font-size: 1.2rem;
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.secondary};

    @media (min-width: 1024px) {
      font-size: 1.8rem;
    }
  }
  
  .separator {
    display: flex;
    margin-right: .5rem;
    margin-left: .5rem;
    font-size: 2rem;
    line-height: .8;
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.secondary};

    @media (min-width: 1024px) {
      margin-right: 2rem;
      font-size: 4rem;
      line-height: .3;
    }
  }
`;