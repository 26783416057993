import styled from 'styled-components';
import { theme } from '../../theme';

export const StyledFAQ = styled.section`
  .faq-item {
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: ${theme.colors.secondary};
    clip-path: polygon(
      5% 0,
      100% 0,
      100% 20%,
      100% 100%,
      80% 100%,
      0 100%,
      0% 80%,
      0 19%
    );
  }

  .faq-header {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 24px;
      margin-right: 1rem;
    }
  }
`
export const StyledAccordion = styled.div`
  display: flex;
  flex-direction: column;
  
  .accordion {
    cursor: pointer;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;
    background-color: ${theme.colors.secondary};
    
    img {
      width: 16px;
      margin-right: .30rem;
      
      @media (min-width: 1024px) {
        width: 24px;
        margin-right: 1em;
      }
    }
  }

  .accordion__title {
    color: ${theme.colors.white};
    padding-left: 1rem;
  }

  .accordion__icon {
    margin-left: auto;
    transition: transform 0.4s ease;
  }

  .rotate {
    transform: rotate(90deg);
  }

  .accordion__content {
    background-color: ${theme.colors.secondary};
    overflow: auto;
    transition: max-height 0.6s ease;
  }

  .accordion__text {
    margin-left: 1rem;
    padding-top: .80rem;
    padding-bottom: .80rem;
    padding-right: .60rem;
    font-size: 18px;
    
    @media (min-width: 1024px) {
      margin-left: 2rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-right: 1rem;
    }
  }
`