import React from 'react';
import { StyledTextImage } from './style';

import Button from '../Button/Button';

const TextImage = ({
  title, image, description, cta, ctaOnClick, invert, className,
}) => {
  return (
    <StyledTextImage
      className={`wrapper medium TextImage ${className}`}
      invert={invert}
    >
      {image && (
        <div
          className="image"
          data-sal="slide-right"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          <img src={image} alt="image" />
        </div>
      )}
      <div className="description">
        {title && (
          <h1
            className="title"
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            {title}
          </h1>
        )}
        {description && (
          <p
            className="info"
            data-sal="slide-left"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            {description}
          </p>
        )}
        {cta && (
          <Button
            className="cta"
            data-sal="slide-left"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="1000"
            onClick={ctaOnClick}
          >
            {cta}
          </Button>
        )}
      </div>
    </StyledTextImage>
  )
}

TextImage.defaultProps = {
  className: ''
};

export default TextImage;