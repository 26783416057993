export const theme = {
  colors: {
    // primary: '#1d0c17',
    primary: "#122441",
    secondary: "#D24A9D",
    black: "#000",
    white: "#fff",
  },
  fonts: {
    primary: "Subadai Baan Bold Italic",
    secondary: "Helvetica",
  },
}