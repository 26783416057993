import React from 'react';
import { StyledButton } from "./style"

const Button = ({
  children, className,
  onClick,
  'data-sal': dataSal,
  'data-sal-easing': dataEasing,
  'data-sal-delay': dataDelay,
  'data-sal-duration': dataDuration,
}) => (
  <StyledButton
    onClick={onClick}
    type="button"
    className={`Button ${className}`}
    data-sal={dataSal}
    data-sal-easing={dataEasing}
    data-sal-delay={dataDelay}
    data-sal-duration={dataDuration}
  >
    {children}
  </StyledButton>
);

Button.defaultProps = {
  className: ''
}

export default Button;