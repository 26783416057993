import styled from 'styled-components';
import { theme } from '../../theme';

export const StyledTextImage = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;

  @media (min-width: 1200px) {
    flex-flow: row nowrap;
  }

  .image {
    width: 100%;
    
    @media (min-width: 1200px) {
      width: calc(100% / 1.2);
      order: ${({ invert }) => invert && 2};
    }
    
    img {
      width: 100%;
    }
  }
  
  .description {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    
    @media (min-width: 1200px) {
      width: calc(100% / 2);
      margin-left: ${({ invert }) => !invert && '2rem'};;
      margin-top: -.3rem;
      order: ${({ invert }) => invert && 1};
      margin-right: ${({ invert }) => invert && '2rem'};
    }

    .title {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      color: ${theme.colors.secondary};
      text-align: center;
      
      @media (min-width: 1200px) {
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }

    .info {
      line-height: 28px;
    }
  }

  p {
    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  .cta {
    flex: 1 0 100%;
    margin-top: 1rem;
  }
`