import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css';
import "swiper/css/autoplay";
import { StyledSlide } from './style'

SwiperCore.use([Autoplay])

const Slide = ({ title, slides }) => {
  const { slidesData: { showcaseTitle, showcaseImages } } = useStaticQuery(graphql`
    query SlidesQuery {
      slidesData: datoCmsHomepage {
        showcaseTitle
        showcaseImages {
          url
        }
      }
    }
  `)
  return (
    <StyledSlide
      className="wrapper"
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-duration="200"
    >
      {showcaseTitle && <h1 className="section-title">{showcaseTitle}</h1>}
      <Swiper
        loop={true}
        loopFillGroupWithBlank={true}
        freeMode
        speed={3500}
        // freeModeMomentum={false}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        spaceBetween={0}
        className="slides"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
          },
          // when window width is >= 640px
          1200: {
            slidesPerView: 5,
          },
        }}
      >
        {showcaseImages?.map(slide => (
          <SwiperSlide className="slide">
            <img src={slide.url} alt="slide" />
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSlide>
  )
};

export default Slide;