import styled from 'styled-components';
import { theme } from '../../theme';

export const StyledHeroSection = styled.section`
  width: 100%;
  height: 30vh;
  position: relative;
  padding-top: .5rem;
  overflow: hidden;
  
  @media (min-width: 420px) {
    height: 50vh;
  }

  @media (min-width: 768px) {
    height: 70vh;
  }
  
  @media (min-width: 1200px) {
    height: 120vh;
    padding-top: 2rem;
  }
  
  @media (min-width: 2560px) {
    height: 65vh;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${theme.colors.primary};
    opacity: 0.4;
    pointer-events: none;
  }
  
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    position: relative;
    z-index: 10;
    
    @media (min-width: 768px) {
      padding: 2rem 3em;
    }
    
    @media (min-width: 1024px) {
      padding: 0 5em;
    }
  }
  
  .socials {
    display: flex;
    align-items: center;
    
    li {
      width: 30px;
      will-change: transform;
      
      &:not(:last-of-type) {
        margin-right: .50rem;

        @media (min-width: 1024px) {
          margin-right: 1rem;
        }
      }
      
      img {
        width: 100%;
      }
    }
  }

  .Logo, .logo {
    position: relative;
    z-index: 1;
  }
  
  .foreground {
    position: absolute;
    z-index: 2;
  }

  .gradient {
    display: block;
    width: 100%;
    height: 30vh;
    position: absolute;
    bottom: -200px;
    left: 0;
    z-index: 2;
    margin-top: -1rem;
    padding-top: 5rem;

    background-image: linear-gradient(
      hsla(323, 60%, 56%, 0),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.8),
      hsla(217, 57%, 16%, 0.7),
      hsla(217, 57%, 16%, 0)
    );
    
    @media (min-width: 2560px) {
      bottom: -445px;
    }
  }
`

export const StyledForeGround = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  z-index: 1;
  will-change: transform;

  width: 80vw;
  
  @media (min-width: 1200px) {
    width: 90vw;
  }

  img {
    width: 100%;
  }
`;