import styled from 'styled-components';
import { theme } from "../../theme";

export const StyledRoadmap = styled.section`
  position: relative;
  padding-top: 2rem;
  padding-bottom: 4rem;
  color: ${theme.colors.primary};
  z-index: 2;
  overflow: hidden;
  
  .section-title {
    margin-bottom: 2rem;
  }
  
  .timeline {
    position: relative;
    max-width: 1200px;
    margin-top: 2rem;
    margin: 0 auto;
  }

  .timeline::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: ${theme.colors.secondary};
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }

  .container {
    padding: 10px 40px;
    position: relative;
    width: 50%;
    
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: -9px;
      background-color: ${theme.colors.secondary};
      top: 15px;
      z-index: 1;
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
  }
  
  .left {
    left: 0;
    //clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0 100%, 0% 56%);
    
    @media (min-width: 768px) {
      text-align: right;
    }
  }

  .right {
    left: 50%;
  }

  .right::after {
    left: -12px;
  }

  .title {
    margin-bottom: .50rem;
    font-size: 1.8rem;
    color: ${theme.colors.secondary};
    
    @media (min-width: 1024px) {
      font-size: 2.5rem;
      margin-bottom: .80rem;
    }
  }

  .content {
    padding: 20px 30px;
    background-color: transparent;
    position: relative;
    border: 1px solid ${theme.colors.secondary};
    color: ${theme.colors.white};
    
    &.isActive {
      background-color: ${theme.colors.secondary};
      
      .title {
        color: ${theme.colors.primary};
      }
    }
  }
  
  @media screen and (max-width: 600px) {
    .timeline::after {
      left: 18px;
    }

    .container {
      width: 100%;
      padding-left: 50px;
      padding-right: 0;
    }

    .container::before {
      left: 60px;
      border: medium solid white;
      border-width: 10px 10px 10px 0;
      border-color: transparent white transparent transparent;
    }

    .left::after, .right::after {
      left: 6px;
    }

    .right {
      left: 0%;
    }
  }
  
  .roadmap-bg {
    display: none;
    
    @media (min-width: 1024px) {
      display: block;
      width: 40vw;
      position: absolute;
      opacity: .3;
      z-index: -1;
    }
    
    img {
      width: 100%;
    }
    
    @media (min-width: 1024px) {
      &.right {
        top: 0;
        left: calc(100% - 40vw);
      }
  
      &.left {
        left: -90px;
        bottom: 0;
      }
    }
  }
`;
