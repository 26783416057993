import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { StyledLogo } from './style';

const Logo = ({ className, style }) => {
  const { logoData: { logo } } = useStaticQuery(graphql`
    query LogoQuery {
      logoData: datoCmsGlobalInfo {
        logo {
          url
        }
      }
    }
  `)
  return (
    <StyledLogo className={`Logo ${className}`} style={style}>
      <img src={logo.url} alt="logo" />
    </StyledLogo>
  )
}

Logo.defaultProps = {
  className: ''
};

export default Logo;