import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Logo } from '../';
import { StyledHeroSection, StyledForeGround } from "./style"

const HeroSection = ({ image }) => {
  const [offsetY, setOffsetY] = useState(0);
  const {
    heroData: { background, foreground },
    socials: { social }
  } = useStaticQuery(graphql`
    query HeroSectionQuery {
      heroData: datoCmsHomepage {
        background {
          url
        }
        foreground {
          url
        }
      }
      socials: datoCmsGlobalInfo {
        social {
          title
          icon {
            url
          }
          link
        }
      }
    }
  `)

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.addEventListener('scroll', handleScroll);
  },[]);

  return (
    <StyledHeroSection
      className="HeroSection"
      style={{
        backgroundImage: `url(${background.url})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <header>
        <Logo style={{transform: `translateY(${offsetY * 0.5}px)`}}/>
        <div className="socials">
          {social?.map((social) => (
            <li style={{transform: `translateY(${offsetY * 0.5}px)`}}>
              <a href={social.link} target="_blank"><img src={social.icon.url} /></a>
            </li>
          ))}
        </div>
      </header>
      {foreground && (
        <StyledForeGround
          style={{transform: `translateY(${offsetY * 0.3}px)`}}
        >
          <img src={foreground.url} alt="foreground" />
          {/*<StaticImage src={foreground.url} alt="foreground" />*/}
        </StyledForeGround>
      )}
      <div className="gradient" />
    </StyledHeroSection>
  )
}

export default HeroSection;