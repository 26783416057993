import React, { useState, useRef } from "react";
import bgSlash from '../../assets/images/bg-slash.svg';
import plusIcon from '../../assets/icons/plus.png';
import { StyledAccordion } from "./style"

export function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  return (
    <StyledAccordion className="mb-sm">
      <div
        className={`accordion ${setActive}`}
        style={{
          background: `url(${bgSlash})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundColor: 'linear-gradient(',
        }}
        onClick={toggleAccordion}
      >
        <h2 className="accordion__title">{props.title}</h2>
        {/*<Chevron className={`${setRotate}`} width={10} fill={"#777"} />*/}
        <img src={plusIcon} alt="plus-icon"/>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <div
          className="accordion__text"
          dangerouslySetInnerHTML={{ __html: props.content }}
        />
      </div>
    </StyledAccordion>
  );
}